






























































import { notifyError, notifySuccess } from "@/components/Notification";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "btn-remove-patient",
  props: {
    patient: Object,
  },
  data() {
    return {
      dialogConfirmRemove: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actRemovePatient"]),
    remove() {
      this.loading = true;
      this.actRemovePatient(this.patient.uuid)
        .then(() => {
          this.dialogConfirmRemove = false;
          this.loading = false;
          notifySuccess(this.getpatientN + this.$t("notifyRemove"));
          this.$router.push("/patients");
        })
        .catch((error) => {
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
